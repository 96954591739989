import { resetBets } from '@/redux/slices/bets'
import { clearBetSlips, placeBet } from '@/redux/slices/betSlips'
import { randomId } from '@/utils/randomId'
import { toPlainString } from '@/utils/toPlainString'
import { useDispatch } from 'react-redux'
import useExchangeRates from './useExchangeRates'

export default function useBets() {
  const dispatch = useDispatch()
  const { usdToBsv } = useExchangeRates()
  const replaceNull = (data) =>
    JSON.parse(JSON.stringify(data).replace(/null/g, false))

  const clearBetsHandler = () => {
    dispatch(clearBetSlips())
    dispatch(resetBets())
  }
  const placeBetHandler = async ({ onComplete, user, ...data }) => {
    let firebaseData

    const bsvBet = usdToBsv(data.price)
    const bsvWinPrice = usdToBsv(data.possibleWinPrice)
    if (data.betType === 'single') {
      const withStatus = data?.betSlips?.map((slip) => {
        return {
          ...slip,
          betPrice: toPlainString(usdToBsv(slip.betPrice)),
          status: 'active',
          betID: randomId(5),
          placedAt: new Date(),
        }
      })

      firebaseData = replaceNull({
        ...data,
        isBsvBet: true,
        price: toPlainString(bsvBet || 0),
        betSlips: withStatus,
        accessToken: user.accessToken,
        referrerInfo: {
          username: user?.username,
          displayName: user?.displayName,
        },
      })
    } else {
      firebaseData = replaceNull({
        ...data,
        isBsvBet: true,
        price: toPlainString(bsvBet || 0),
        possibleWinPrice: toPlainString(bsvWinPrice || 0),
        status: 'active',
        referrerInfo: {
          username: user?.username,
          displayName: user?.displayName,
        },
        betID: randomId(5),
        accessToken: user.accessToken,
        placedAt: new Date(),
      })
    }

    dispatch(
      placeBet({
        uid: user?.uid,
        data: firebaseData,
      }),
    )

    onComplete && onComplete(true)
  }

  return {
    placeBetHandler,
    clearBetsHandler,
  }
}
